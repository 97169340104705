.overflow-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      overflow: visible;
      white-space: normal;
      height: auto;
    }
}

.max-two-lines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (display: -webkit-box) {
  .max-two-lines {
    /* autoprefixer: off */
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden !important;
    white-space: normal !important;
  }
}

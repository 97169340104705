@import '../abstracts/variables.scss';
// stile globale aggiunto per correggere un conglitto ng-zorro / tailwind
.btn-icon-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text-and-icons-aligned {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bottone {
    background-color: $primary-400;
    border-radius: 4px;
  }
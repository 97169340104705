
$light-orange: rgba(247, 203, 8);
$primary-400: #028DAD;
$dark-bg: #999;
$light-bg: #ddd;
$project-menu-color:#F4F5F7;
$left-navbar: #E2E8F0;
$gisfo-color: #fd7d21;
$om-gray100: #F7F9F9;
$om-gray200: #E8EFEE;
$om-gray300: #DFE1E6;
$om-gray400: #A7C0BF;
$white07: hsla(0, 0%, 100%, 0.7);
$om-primary600: #008FC1; 
$om-primary200: #8FE3EC; 
$om-primary700: #00576B;
$om-primary800: #013743;
$om-border: 1px solid $om-gray400;
$om-success: #00D584;
$om-black: #000A0C;

@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  height: 100dvh;
  width: 100dvw;
  box-sizing: border-box;
  overflow: hidden;
  touch-action: none;
}

textarea {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.debug {
  background-color: black;
  color: #df7edf;
  padding: 4px;
  border-radius: 6px;
}

.debug-lite {
  border: 1px solid blue;
  border-radius: 3px;
}

.pre-bootstrapping-container {
  @apply w-full h-full relative;
}
.pre-bootstrapping-container .pre-bootstrapping-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gray {
  color: #aaa;
}

.black {
  color: #000;
}

a {
  @apply text-blue-600;
}
a:hover {
  @apply text-blue-800;
}

.ant-layout-header {
  padding: 0 2rem;
  background-color: #fff;
  height: unset;
}

.ant-layout-content {
  padding: 1rem 2rem;
  background-color: #fff;
}

.ant-layout-footer {
  padding: 1rem 2rem;
  background-color: #fff;
}

.ant-page-header {
  padding: 1rem 0 0;
}
.ant-page-header .ant-page-header-heading-title {
  font-size: 1.5rem;
  color: #42526e;
}

.ant-collapse {
  background-color: transparent;
  border: unset;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ant-collapse .ant-collapse-item {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fafafa;
}

.ant-collapse-header {
  align-items: center !important;
}
.ant-collapse-header div:first-child {
  display: flex;
}

.ant-collapse-extra {
  display: flex;
  align-items: center;
  font-size: smaller;
  gap: 0.5rem;
  color: #aaa;
}
.ant-collapse-extra i {
  font-size: 20px;
}

.ant-list-item.no-border {
  border: none;
  padding: 0.5rem 0 0;
}

.ant-modal-wrap {
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
}
.ant-modal-wrap .issue-modal {
  @apply modal-fullscreen;
}
.ant-modal-wrap .issue-modal .ant-modal-content {
  @apply modal-content-fullscreen;
}
.ant-modal-wrap .photo-modal {
  @apply modal-fullscreen;
}
.ant-modal-wrap .photo-modal .ant-modal-content {
  @apply modal-content-fullscreen;
  background: rgba(0, 0, 0, 0.4);
}
.ant-modal-wrap .alert-modal {
  @apply modal-fullscreen;
}
.ant-modal-wrap .alert-modal .ant-modal-content {
  max-height: 90vh;
  max-width: 90vw;
  border-radius: 6px;
}
@media only screen and (max-width: 700px) {
  .ant-modal-wrap .alert-modal .ant-modal-content {
    width: 90vw;
  }
}
@media only screen and (min-width: 700px) {
  .ant-modal-wrap .alert-modal .ant-modal-content {
    width: 450px;
  }
}
.ant-modal-wrap .alert-modal .ant-modal-content .ant-modal-body {
  height: 100%;
}

.modal-fullscreen {
  position: fixed;
  inset: 0;
  max-width: unset;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.modal-content-fullscreen {
  height: 100%;
  width: 100%;
}
.modal-content-fullscreen .ant-modal-body {
  height: 100%;
}

app-appointment-calendar .ant-picker-calendar-header {
  display: none !important;
}

.only-dot .ant-badge-status-text {
  margin: 0;
}

.ant-notification-notice-description {
  white-space: pre-line;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow-ellipsis:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}

.max-two-lines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (display: -webkit-box) {
  .max-two-lines {
    /* autoprefixer: off */
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden !important;
    white-space: normal !important;
  }
}
.btn-icon-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-and-icons-aligned {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottone {
  background-color: #028DAD;
  border-radius: 4px;
}

.global-form-wrapper {
  width: 600px;
}

.by-gisfo {
  border-left: 20px solid #fd7d21;
}

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 2px;
  margin: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #cfd6e0;
  border-radius: 2px;
  border-width: 6px;
  border-style: solid;
  border-color: white;
}

::-webkit-scrollbar-thumb:hover {
  background: #aabace;
  border-width: 5px;
  border-style: solid;
  border-color: white;
}

.fg {
  display: flex;
}

.ftitle {
  background-color: #f7f7f7;
  color: #333;
  border-radius: 6px;
  padding: 2px 2px 2px 8px;
  margin: 0px 0px 14px 0px;
  font-size: 12pt;
  font-weight: bold;
}

.editor-size-select {
  width: 100px;
}

.toolbar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.buttons-vertical {
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.buttons-horizontal {
  padding: 2px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.buttons-inline {
  padding: 2px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.mr-8 {
  margin-right: 8px;
}

.fi {
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 2px;
}

.fi-xsmall {
  flex: 1;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-small {
  flex: 2;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-normal {
  flex: 3;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-large {
  flex: 4;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-xlarge {
  flex: 5;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-full {
  flex: 6;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.icon-24 {
  font-size: 24px;
}

.debug {
  border: 1px solid blue;
  background-color: #0073ff;
}

.layer-switcher {
  background-color: transparent !important;
  top: 0.5em !important;
  right: 0.5em !important;
}

/* Menù */
.layer-switcher .panel {
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 0.7rem;
}

/* Menù container */
.layer-switcher .panel ul:first-child {
  margin: 8px;
}

/* Menù list container */
.layer-switcher .panel ul ul {
  padding-left: 6px;
}

/* Menù list element */
.layer-switcher .panel li.layer {
  display: flex;
  align-items: center;
}

/* Bottone Menù */
.layer-switcher button {
  left: -1px !important;
  top: -1px !important;
}

/* Bottone Menù : on hover */
.layer-switcher button:focus {
  outline: none !important;
  background-color: #eee !important;
}

.divider-top {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #A7C0BF;
}

.divider-bottom {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #DFE1E6;
}
::-webkit-scrollbar{
  width: 1rem;
  // height: .8rem;
}

::-webkit-scrollbar-track{
  background: transparent;
  // background: #fcfdff ;
  border-radius: 2px;
  margin: .5rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent
  // border-color: #aabace;
}

::-webkit-scrollbar-thumb{
  background: #cfd6e0;
  border-radius: 2px;
  border-width: 6px;
  border-style: solid;
  border-color: white;
}

::-webkit-scrollbar-thumb:hover{
  background: #aabace ;
  border-width: 5px;
  border-style: solid;
  border-color: white;
}
